import {Recipient, WhoToAdd, RecipientToAdd} from './QuickSelect'

export const buildRecipients = (
  selectedRecipients: Recipient[],
  hasStudentSquare: boolean,
  whoToAdd: WhoToAdd
) => {
  const recipientsToAdd: RecipientToAdd[] = []
  selectedRecipients.forEach((selectedRecipient) => {
    if (selectedRecipient.type === 'Student') {
      if (!hasStudentSquare || whoToAdd.user.includes('parents')) {
        selectedRecipient.parents?.forEach((parent) => {
          if (parent.login_allowed) {
            recipientsToAdd.push({
              recipient_type: 'Person',
              recipient_id: parent.id,
              descriptive_name: `${parent.last_name}, ${parent.first_name}`,
              user_ids: [parseInt(parent.id)],
            })
          }
        })
      }
      if (
        whoToAdd.user.includes('students') &&
        selectedRecipient.associated_user_id &&
        selectedRecipient.login_allowed
      ) {
        recipientsToAdd.push({
          recipient_type: 'Person',
          recipient_id: selectedRecipient.associated_user_id,
          descriptive_name: `${selectedRecipient.last_name}, ${selectedRecipient.first_name}`,
          user_ids: [parseInt(selectedRecipient.associated_user_id)],
        })
      }
    }
    if (['Parent', 'Staff', 'Guest'].includes(selectedRecipient.type)) {
      recipientsToAdd.push({
        recipient_type: 'Person',
        recipient_id: selectedRecipient.id,
        descriptive_name: `${selectedRecipient.last_name}, ${selectedRecipient.first_name}`,
        user_ids: [parseInt(selectedRecipient.id)],
      })
    }

    if (selectedRecipient.type === 'Class') {
      const includedIds = new Set<number>()
      const who = whoToAdd.section
      if (who.includes('students')) {
        selectedRecipient.student_recipient_ids?.forEach(includedIds.add, includedIds)
      }
      if (who.includes('parents')) {
        selectedRecipient.parent_recipient_ids?.forEach(includedIds.add, includedIds)
      }
      if (who.includes('staff')) {
        selectedRecipient.staff_recipient_ids?.forEach(includedIds.add, includedIds)
      }

      if (includedIds.size > 0) {
        recipientsToAdd.push({
          recipient_type: 'Section',
          recipient_id: selectedRecipient.id,
          descriptive_name: selectedRecipient.full_name,
          roles: who,
          user_ids: [...includedIds],
        })
      }
    }

    if (selectedRecipient.type === 'Group') {
      const includedIds = new Set<number>()
      const who = whoToAdd.group
      if (who.includes('everyone')) {
        selectedRecipient.student_recipient_ids?.forEach(includedIds.add, includedIds)
        selectedRecipient.parent_recipient_ids?.forEach(includedIds.add, includedIds)
        selectedRecipient.staff_recipient_ids?.forEach(includedIds.add, includedIds)
        selectedRecipient.guest_recipient_ids?.forEach(includedIds.add, includedIds)
      } else {
        if (who.includes('students')) {
          selectedRecipient.student_recipient_ids?.forEach(includedIds.add, includedIds)
        }
        if (who.includes('parents')) {
          selectedRecipient.parent_recipient_ids?.forEach(includedIds.add, includedIds)
        }
        if (who.includes('staff')) {
          selectedRecipient.staff_recipient_ids?.forEach(includedIds.add, includedIds)
        }
        if (who.includes('guests')) {
          selectedRecipient.guest_recipient_ids?.forEach(includedIds.add, includedIds)
        }
      }
      if (includedIds.size > 0) {
        recipientsToAdd.push({
          recipient_type: 'Group',
          recipient_id: selectedRecipient.id,
          descriptive_name: selectedRecipient.full_name,
          roles: who.includes('everyone') ? ['all'] : who,
          user_ids: [...includedIds],
        })
      }
    }
  })

  return recipientsToAdd
}
