import React from 'react'
import './WhoToAdd.scss'
import type {WhoToAddOption} from '../QuickSelect'

interface Props {
  title: string
  whoToAddOptions: WhoToAddOption[]
  onWhoToAddChange: (option: string) => void
  whoToAdd: string[]
}

const WhoToAdd = ({title, whoToAddOptions, onWhoToAddChange, whoToAdd}: Props) => {
  return (
    <>
      <div className="who-to-add">
        <div className="who-to-add-title">{title}</div>
        <div className="who-to-add-options">
          {whoToAddOptions.map((whoToAddOption) => (
            <div key={whoToAddOption.key} className="who-to-add-option">
              <label
                key={whoToAddOption.key}
                htmlFor={whoToAddOption.key}
                className="who-to-add-description"
              >
                <input
                  onChange={() => onWhoToAddChange(whoToAddOption.key)}
                  id={whoToAddOption.key}
                  className="who-to-add-check"
                  type="checkbox"
                  checked={whoToAdd.includes(whoToAddOption.key)}
                />
                {whoToAddOption.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default WhoToAdd
