import {QuickSelectI18nScope, ResponseType} from './QuickSelect'
import {parseResponse} from './parseResponse'
import type {Recipient} from './QuickSelect'
import {Noticeable} from '@honeybadger-io/core/build/src/types'
// eslint-disable-next-line
// @ts-ignore
export const jsonApiService = JsonApiServiceObj.getJsonApiService()
// eslint-disable-next-line
// @ts-ignore
export const apiRoot = JsonApiServiceObj.getJsonApiRoot()

export const fetchRecipientData = async (
  currentInstituteType: string,
  currentInstituteId: number,
  recipientType: string,
  i18nQS: QuickSelectI18nScope['quick_select'],
  searchQuery: string,
  cursorToStartAt: string | null
) => {
  const uri = buildUri(
    currentInstituteType,
    currentInstituteId,
    recipientType,
    searchQuery,
    cursorToStartAt
  )

  return jsonApiService
    .request(uri, 'GET')
    .then((res: ResponseType) => {
      const recipients = parseResponse(res, recipientType, i18nQS)
      const response: {
        recipients: Recipient[]
        cursor: string | null
      } = {
        recipients,
        cursor: null,
      }
      const pageSize = res?.meta?.page_size
      if (pageSize && recipients.length === pageSize) {
        response.cursor = res.meta.start_from
      }

      return response
    })
    .catch((error: Noticeable) => {
      window.Honeybadger.notify(error)
    })
}

const buildUri = (
  currentInstituteType: string,
  currentInstituteId: number,
  recipientType: string,
  searchQuery: string,
  cursorToStartAt: string | null
) => {
  let uri = `${apiRoot}/${currentInstituteType}/${currentInstituteId}/chats/recipients?`
  uri += `recipient_type=${recipientType}`
  uri += cursorToStartAt ? '&start_from=' + cursorToStartAt : ''
  uri += searchQuery !== '' ? '&search_query=' + searchQuery : ''
  return uri
}
