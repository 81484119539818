import {
  ResponseType,
  QuickSelectI18nScope,
  Recipient,
  UserResponseType,
  SectionResponseType,
  GroupResponseType,
  countGroupUsers,
} from './QuickSelect'

export const parseResponse = (
  res: ResponseType,
  recipientTypeKey: string,
  i18nQS: QuickSelectI18nScope['quick_select']
): Recipient[] => {
  let recipients: Recipient[] = []
  if (recipientTypeKey === 'user') {
    const response = res as UserResponseType
    const mappedStudents = response.data.student_recipients.map(
      (studentRecipient): Recipient => ({
        ...studentRecipient,
        type: 'Student',
      })
    )

    const mappedStaff = response.data.staff_recipients.map(
      (staffRecipient): Recipient => ({
        ...staffRecipient,
        type: 'Staff',
      })
    )

    recipients = [...mappedStudents, ...mappedStaff]
    recipients.sort((a, b) => {
      if (a.full_name > b.full_name) return 1
      if (a.full_name < b.full_name) return -1
      return 0
    })
  } else if (recipientTypeKey === 'section') {
    const response = res as SectionResponseType
    recipients = response.data.map(
      (sectionRecipient): Recipient => ({
        id: sectionRecipient.section_id,
        full_name: sectionRecipient.section_name,
        staff_recipient_ids: sectionRecipient.staff_recipient_ids,
        parent_recipient_ids: sectionRecipient.parent_recipient_ids,
        student_recipient_ids: sectionRecipient.student_recipient_ids,
        type: 'Class',
      })
    )
  } else if (recipientTypeKey === 'group') {
    const response = res as GroupResponseType
    recipients = response.data.map(
      (groupRecipient): Recipient => ({
        id: groupRecipient.group_id,
        full_name: groupRecipient.group_name,
        title: i18nQS.recipient_list.group.title.replace(
          '%{count}',
          `${countGroupUsers(groupRecipient)}`
        ),
        staff_recipient_ids: groupRecipient.staff_recipient_ids,
        parent_recipient_ids: groupRecipient.parent_recipient_ids,
        student_recipient_ids: groupRecipient.student_recipient_ids,
        guest_recipient_ids: groupRecipient.guest_recipient_ids,
        type: 'Group',
      })
    )
  }
  return recipients
}
