import React from 'react'
import './RecipientTypeList.scss'
import Item from './Item'
import type {RecipientType, WhoToAddKey} from '../QuickSelect'

interface Props {
  types: RecipientType[]
  selected: WhoToAddKey
  onSelect: (type: RecipientType) => void
}

const RecipientTypeList = ({types, selected, onSelect}: Props) => {
  return (
    <div className="list">
      {types.map((type) => (
        <Item
          key={type.key}
          onClick={() => {
            onSelect(type)
          }}
          iconName={type.iconName}
          title={type.description}
          selected={selected === type.key}
        />
      ))}
    </div>
  )
}

export default RecipientTypeList
