import React, {useEffect, useRef} from 'react'
import Spinner from './Spinner'
import './LoadMore.scss'

interface Props {
  callWhenVisible: () => void
}
const LoadMore = ({callWhenVisible}: Props) => {
  const elementRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          callWhenVisible()
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    )

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        // This needs to be fixed and the eslint rule re-enabled.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(elementRef.current)
      }
    }
    // This needs to be fixed and the eslint rule re-enabled.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="load-more" ref={elementRef} data-testid="load-more">
      <Spinner />
    </div>
  )
}

export default LoadMore
