import {QuickSelectI18nScope} from './QuickSelect'

export const buildFooterMessage = (
  recipientUserIdsSets: {
    parent: Set<number>
    student: Set<number>
    staff: Set<number>
    guest: Set<number>
    all: Set<number>
  },
  maximumRecipientsToAdd: number,
  i18nQS: QuickSelectI18nScope['quick_select']
) => {
  const allCount = recipientUserIdsSets.all.size
  const willbeAdded =
    allCount === 1 ? i18nQS.footer.will_be_added.one : i18nQS.footer.will_be_added.other

  const parentCount = recipientUserIdsSets.parent.size
  const studentCount = recipientUserIdsSets.student.size
  const staffCount = recipientUserIdsSets.staff.size
  const guestCount = recipientUserIdsSets.guest.size

  const roleToBeAdded: string[] = []
  if (parentCount > 0)
    roleToBeAdded.push(
      `${parentCount} ${parentCount > 1 ? i18nQS.footer.parents : i18nQS.footer.parent}`
    )
  if (studentCount > 0)
    roleToBeAdded.push(
      `${studentCount} ${studentCount > 1 ? i18nQS.footer.students : i18nQS.footer.student}`
    )
  if (staffCount > 0)
    roleToBeAdded.push(
      `${staffCount} ${staffCount > 1 ? i18nQS.footer.staff_members : i18nQS.footer.staff_member}`
    )
  if (guestCount > 0)
    roleToBeAdded.push(
      `${guestCount} ${guestCount > 1 ? i18nQS.footer.guests : i18nQS.footer.guest}`
    )

  if (allCount > maximumRecipientsToAdd) {
    return {
      text: i18nQS.footer.reduce_to_fewer_than.replace('%{count}', `${maximumRecipientsToAdd}`),
      type: 'warning',
    }
  }

  if (allCount === 0) {
    return {
      text: `${i18nQS.footer.make_selections_up_to_recipients.replace('%{count}', `${maximumRecipientsToAdd}`)}`,
      type: 'info',
    }
  }

  return {
    text: `${willbeAdded.replace('%{recipients}', `${allCount}`)} (${roleToBeAdded.join(', ')})`,
    type: 'info',
  }
}
