import React, {useContext, useEffect, useState, useRef} from 'react'
import {I18nContext} from '@/context/i18n/I18nContext'
import type {QuickSelectI18nScope} from '../QuickSelect'
import './Footer.scss'

interface Props {
  onAddRecipientsClick: () => void
  footerMessage: {text: string; type: string}
  lastUncontactableUsers: string[]
  disabled: boolean
}

// needs to be in synch with the animation in Footer.scss
const MILISECONDS_TO_SHOW_TEMPORARY_MESSAGE = 3000

const Footer = ({onAddRecipientsClick, footerMessage, lastUncontactableUsers, disabled}: Props) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select
  const [message, setMessage] = useState<string>('')
  const [className, setClassName] = useState<string>('')
  const statusClassName = footerMessage.type === 'warning' ? 'status status-warning' : 'info'
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)
  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }
  useEffect(() => {
    if (lastUncontactableUsers.length > 0) {
      resetTimeout()
      let uncontactableUsers = lastUncontactableUsers[0]
      if (lastUncontactableUsers.length > 1) {
        const andMore = i18nQS.footer.and_n_more.replace(
          '%{count}',
          `${lastUncontactableUsers.length - 1}`
        )
        uncontactableUsers += ` ${andMore}`
      }
      const notContactable =
        lastUncontactableUsers.length === 1
          ? i18nQS.footer.is_not_contactable.one
          : i18nQS.footer.is_not_contactable.other

      setMessage(`${uncontactableUsers} ${notContactable}`)
      setClassName('fade out')
      timeoutId.current = setTimeout(() => {
        setMessage(footerMessage.text)
        setClassName('')
      }, MILISECONDS_TO_SHOW_TEMPORARY_MESSAGE)
    } else {
      resetTimeout()
      setMessage(footerMessage.text)
      setClassName('')
    }
  }, [lastUncontactableUsers, setMessage, footerMessage, i18nQS])

  return (
    <div className="footer-container">
      <div key={message} className={`footer-message ${className} ${statusClassName}`}>
        {message}
      </div>
      <button
        type="button"
        onClick={onAddRecipientsClick}
        disabled={disabled}
        className="footer-button btn btn-primary"
        data-testid="add-recipients-button"
      >
        {i18nQS.footer.add_recipients}
      </button>
    </div>
  )
}

export default Footer
