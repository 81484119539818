import React from 'react'
import './Spinner.scss'

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </div>
  )
}

export default Spinner
