import {Recipient, WhoToAdd} from './QuickSelect'

export const getSelectedRecipientsUserIdSets = (
  selectedRecipients: Recipient[],
  whoToAdd: WhoToAdd,
  hasStudentSquare: boolean,
  initialRecipients: Set<number>
): {
  parent: Set<number>
  student: Set<number>
  staff: Set<number>
  guest: Set<number>
  all: Set<number>
} => {
  const parentsSet = new Set<number>()
  const studentsSet = new Set<number>()
  const staffSet = new Set<number>()
  const guestsSet = new Set<number>()
  const allSet = new Set<number>()

  const whoToAddOnUsersTab = whoToAdd.user
  const who = hasStudentSquare ? whoToAddOnUsersTab : ['parents']
  selectedRecipients
    .filter((r) => r.type === 'Student' || r.type === 'Staff')
    .forEach((person) => {
      if (who.includes('parents') && person.type === 'Student') {
        person.parents?.forEach((parent) => {
          if (parent.login_allowed) {
            parentsSet.add(parseInt(parent.id))
            allSet.add(parseInt(parent.id))
          }
        })
      }
      if (who.includes('students') && person.type === 'Student' && person.login_allowed) {
        studentsSet.add(parseInt(person.id))
        allSet.add(parseInt(person.id))
      }
      if (person.type === 'Staff') {
        staffSet.add(parseInt(person.id))
        allSet.add(parseInt(person.id))
      }
    })

  const whoToAddOnClassesTab = whoToAdd.section

  selectedRecipients
    .filter((r) => r.type === 'Class')
    .forEach((section) => {
      if (whoToAddOnClassesTab.includes('parents'))
        section.parent_recipient_ids?.forEach((i) => {
          parentsSet.add(i)
          allSet.add(i)
        })
      if (whoToAddOnClassesTab.includes('students'))
        section.student_recipient_ids?.forEach((i) => {
          studentsSet.add(i)
          allSet.add(i)
        })
      if (whoToAddOnClassesTab.includes('staff'))
        section.staff_recipient_ids?.forEach((i) => {
          staffSet.add(i)
          allSet.add(i)
        })
    })

  const whoToAddOnGroupsTab = whoToAdd.group

  const addParents =
    whoToAddOnGroupsTab.includes('parents') || whoToAddOnGroupsTab.includes('everyone')
  const addStudents =
    whoToAddOnGroupsTab.includes('students') || whoToAddOnGroupsTab.includes('everyone')
  const addStaff = whoToAddOnGroupsTab.includes('staff') || whoToAddOnGroupsTab.includes('everyone')
  const addGuests =
    whoToAddOnGroupsTab.includes('guests') || whoToAddOnGroupsTab.includes('everyone')
  selectedRecipients
    .filter((r) => r.type === 'Group')
    .forEach((group) => {
      if (addParents)
        group.parent_recipient_ids?.forEach((i) => {
          parentsSet.add(i)
          allSet.add(i)
        })
      if (addStudents)
        group.student_recipient_ids?.forEach((i) => {
          studentsSet.add(i)
          allSet.add(i)
        })
      if (addStaff)
        group.staff_recipient_ids?.forEach((i) => {
          staffSet.add(i)
          allSet.add(i)
        })
      if (addGuests)
        group.guest_recipient_ids?.forEach((i) => {
          guestsSet.add(i)
          allSet.add(i)
        })
    })

  initialRecipients.forEach((r) => {
    parentsSet.delete(r)
    studentsSet.delete(r)
    staffSet.delete(r)
    guestsSet.delete(r)
    allSet.delete(r)
  })

  return {
    parent: parentsSet,
    student: studentsSet,
    staff: staffSet,
    guest: guestsSet,
    all: allSet,
  }
}
